import React, { useCallback, useMemo, useState, createContext} from 'react';
import ConfirmModal from './ConfirmModal/ConfirmModal';

const INITIAL_VALUE = {
  title: '',
  subtitle: '',
  warningText: '',
  icon: <></>,
  cancelText: '',
  confirmText: '',
  hideCancel: false,
  disableClose: false,
  onCancel: () => {},
  onConfirm: () => {},
};

const ConfirmModalContext = createContext({
  confirmModalData: INITIAL_VALUE,
  showConfirmModal: (values) => {},
  closeConfirmModal: () => {},
});

const ConfirmModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [confirmModalData, setConfirmModal] = useState(INITIAL_VALUE);

  const showConfirmModal = useCallback((values) => {
    setConfirmModal(values);
    setIsOpen(true);
  }, []);

  const closeConfirmModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const contextValue = useMemo(
    () => ({
      confirmModalData,
      showConfirmModal,
      closeConfirmModal,
    }),
    [confirmModalData, showConfirmModal, closeConfirmModal]
  );

  return (
    <ConfirmModalContext.Provider value={contextValue}>
      <ConfirmModal {...confirmModalData} isOpen={isOpen} onClose={() => setIsOpen(false)} />
      {children}
    </ConfirmModalContext.Provider>
  );
};

export { ConfirmModalContext, ConfirmModalProvider };
