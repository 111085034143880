import { TextField, styled } from "@mui/material";
import { themeValues } from "../../../theme/theme";

export const StyledTextField = styled(
  TextField,
)(({ fontColor }) => ({
  input: {
    border: 0,
    color: 'white',
  },
  backgroundColor: themeValues.brandColors.darkGrey,
}));