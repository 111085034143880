import { Box, styled } from "@mui/material";

export const CustomBox = styled(Box) (({ theme }) => ({
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(10),
  backgroundColor: 'black',
  [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
  }
}));

export const BoxText = styled(Box) (({ theme }) => ({
  flex: '1',
  paddingLeft: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
      flex: '2',
      textAlign: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
  },
}));