import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';


export const themeValues = {
  brandColors: {
    black: '#111116',
    yellow: '#ffde5a',
    purple: '#7251bd',
    darkGrey: '#1a1a1a'
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: grey[50]
    }
  }
});