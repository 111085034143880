import React from 'react'
import { 
  Stack, 
  Typography,
} from '@mui/material'
import Link from '@mui/material/Link';
import FooterLink from './FooterLink'
import { BoxRow, StackColumn } from './Styled';
import { socialMedia } from '../../../constants/socialMedia';
import { textConstants } from '../../../constants/textConstants';
import { termsAndConditionsRoute, privacyPolicyRoute } from '../../../constants/legal';

const Footer = () => {
  return (
    <BoxRow 
      component = 'footer'
      sx={{
        py: 4,
        px: 2,
      }}
    >
      <StackColumn>
        <FooterLink
          key="address"
          text={textConstants.footerAddress} 
        />
        <FooterLink 
          key="phone"
          text={textConstants.footerPhone} 
        />
        <FooterLink
          key="email"
          text={textConstants.footerEmail}
        />
      </StackColumn>
   
      <StackColumn>
        <Stack 
          direction='row' 
          maxWidth='100%'
          justifyContent='space-between'
        >
          {
            socialMedia?.map(item => (
              <Link href={item?.link} variant="body2" key={item?.link} 
                sx={{
                  color: '#414141',
                  "&:hover": {
                    color: '#1c2859',
                  }
                }}
              >
                {item?.Icon}
              </Link> 
            ))
          }
        </Stack>
        <Typography 
          variant='caption'
          component='p' 
        >
          &copy; {textConstants.footerCopyright}
        </Typography>
      </StackColumn>

      <StackColumn> 
        <Typography 
          variant='p'
          component='h5' 
        >
          Legal
        </Typography>
        <FooterLink
          key="terms"
          text={'TERMS & CONDITION'}
          to={termsAndConditionsRoute}
        />
        <FooterLink 
          key="privacy"
          text={'PRIVACY POLICY'}
          to={privacyPolicyRoute}
        />
      </StackColumn>
    </BoxRow>
  )
}

export default Footer