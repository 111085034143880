import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Get the base URL without query parameters or fragments
const baseUrl = window.location.origin + window.location.pathname;
console.log(baseUrl);
// Only clean the URL if it's not already clean
if (window.location.href !== baseUrl) {
  window.history.pushState({}, document.title, baseUrl);
  // No need to reload the page after updating the URL
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
