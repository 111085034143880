import React from 'react';
import {
  ButtonSeparator,
  CloseButton,
  SubtitleDiv,
  TitleDiv,
} from './Styled';
import { Box, Typography, Button, Modal } from '@mui/material';
import { Column, Row4 } from '../../components/common/CommonStyled';
import { themeValues } from '../../theme/theme';

const ConfirmModal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  icon,
  cancelText,
  confirmText,
  warningText,
  hideCancel,
  customContent,
  disableCancelStyle,
  disableClose,
  onCancel,
  onConfirm,
}) => {

  const handleCloseModal = () => {
    onClose();
    onCancel();
  };

  const handleConfirmModal = () => {
    onClose();
    onConfirm();
  };

  return (
    <Modal
      open={isOpen}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Column
        sx={{
          paddingBottom: { sx: '16px', md: '0px' },
          paddingX: '16px',
          position: 'absolute',
          boxShadow: 24,
          borderRadius: 4,
          '&:focus': {
            outline: 'none',
          },
          maxWidth: { xs: '100%', sm: '60%' },
          width: 'auto',
          background: themeValues.brandColors.black
        }}
      >
        <Row4 width="100%" paddingRight="12px" paddingTop="12px">
          {!disableClose ? (
            <CloseButton onClick={onClose} />
          ) : null}
        </Row4>

        <Column>
          {icon}

          {title ? (
            <TitleDiv>
              <Typography
                variant="h6"
                component="h6"
                color="white"
                fontWeight="bold"
              >
                {title}
              </Typography>
            </TitleDiv>
          ) : null}

          {subtitle ? (
            <SubtitleDiv>
              <Typography
                variant="p"
                component="p"
                color="white"
              >
                {subtitle}
              </Typography>
            </SubtitleDiv>
          ) : null}

          {customContent ?? null}

          {warningText ? (
            <SubtitleDiv>
              <Typography
                variant="h6"
                component="h3"
                color={themeValues.brandColors.yellow}
              >
                {warningText}
              </Typography>
            </SubtitleDiv>
          ) : null}

          <Box
            sx={{
              display: 'flex',
              paddingTop: { xs: '16px', sm: '32px' },
              paddingBottom: { xs: '32px', sm: '32px' },
              flexDirection: { xs: 'column', sm: 'row-reverse' },
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              type="button"
              onClick={handleConfirmModal}
              height="38px"
              width="277px"
            >
              {confirmText ?? 'Yes'}
            </Button>

            {!hideCancel ? (
              <>
                <ButtonSeparator />

                <Button
                  type="button"
                  onClick={handleCloseModal}
                  height="38px"
                  width="277px"
                  color="error"
                >
                  {cancelText ?? 'Cancel'}
                </Button>
              </>
            ) : null}
          </Box>
        </Column>
      </Column>
    </Modal>
  );
};

export default ConfirmModal;
