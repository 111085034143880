export const textConstants = {
  navbarTitle: 'AdzEagle',
  homeHeaderTitle: 'AdzEagle',
  homeHeaderDescription: 'Earn real cash by playing games! Dive into exciting challenges with our app and turn your gaming skills into real money. It’s not just gaming – it’s a cash-earning adventure where your victories lead to real rewards!',
  homeHeaderButton: 'Explore our apps!',
  ourLastestApps: 'Our latest apps',
  contactTitle: 'Contact us!',
  contactDescription: 'We’d love to hear from you! Whether you have a question, feedback, or just want to say hello, feel free to reach out to us. Our team is here to help and ensure you have the best experience with AdzEagle. Drop us a message and we’ll get back to you as soon as possible.',
  contactButton: 'Send message!',
  contactName: 'Your name:',
  contactEmail: 'Your email:',
  contactMessage: 'Message:',
  footerAddress: 'company address',
  footerPhone: 'company phone',
  footerEmail: 'support@adzeagle.com',
  footerCopyright: '2024 AdzEagle'
}