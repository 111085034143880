const PrivacyPolicyWeb = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Privacy Policy</h1>
            <p style={styles.paragraph}>
                <strong>*Introduction*</strong>
                <br /> <br />
                Welcome to AdzEagle. This Privacy Policy explains how we collect, use, and disclose your personal information when you use our website and services. It also outlines your privacy rights and how the law protects you. By using our services, you consent to the collection and use of your information in accordance with this Privacy Policy.
                <br /><br />
                <strong>*Interpretation and Definitions*</strong>
                <br /> <br />
                <strong>*Interpretation*</strong>
                <br /> <br />
                Words with initial capital letters have meanings defined by the following conditions. These definitions apply to both singular and plural forms.
                <br /><br />
                <strong>*Definitions*</strong>
                <br /> <br />
                <strong>*Account* : </strong>A unique account created for you to access parts of our service.
                <br />
                <strong>*Affiliate* : </strong>An entity that controls, is controlled by, or is under common control with another entity, where "control" means ownership of 50% or more of the shares or equity interest.
                <br />
                <strong>*Application* : </strong>Refers to AdzEagle, the software provided by the Company.
                <br />
                <strong>*Company* : </strong>Refers to AdzEagle, located in [Your Address].
                <br />
                <strong>*Country* : </strong>Refers to [Your Country].
                <br />
                <strong>*Device* : </strong>Any device capable of accessing the Service, such as a computer or smartphone.
                <br />
                <strong>*Personal Data* : </strong>Information that identifies or can be used to identify an individual.
                <br />
                <strong>*Service* : </strong>Refers to the Application.
                <br />
                <strong>*Service Provider* : </strong>A third-party company or individual employed to facilitate the Service, perform services related to the Service, or analyze how the Service is used.
                <br />
                <strong>*Usage Data* : </strong>Data collected automatically from the use of the Service or its infrastructure, including your IP address, browser type, and usage patterns.
                <br />
                <strong>*You* : </strong>The individual accessing or using the Service, or the company or legal entity on behalf of whom the individual is using the Service.
                <br /><br />
                <strong>*Collection and Use of Personal Data*</strong>
                <br /> <br />
                <strong>*Types of Data Collected*</strong>
                <br /><br />
                <strong>*Personal Data* : </strong>When using our Service, we may request personal information such as :<br />
                - Name<br />
                - Email<br /><br />
                <strong>*Usage Data* : </strong>Automatically collected data when using the Service, including :<br />
                - IP address<br />
                - Browser type and version<br />
                - Pages visited<br />
                - Time and date of visits<br />
                - Device identifiers<br /><br />
                <strong>*Use of Personal Data*</strong><br /><br />
                We may use your personal data for various purposes, including :<br />
                - Providing and maintaining our Service<br />
                - Managing your Account and registration<br />
                - Performing contracts and transactions<br />
                - Contacting you with updates and promotional information<br />
                - Handling your requests and inquiries<br />
                - Conducting business transfers<br />
                - Analyzing data to improve our Service and marketing efforts<br /><br />
                <strong>*Disclosure of Personal Data*</strong><br /><br />
                We may share your personal data in the following situations :<br />
                <strong>*With Service Providers* : </strong>To facilitate and analyze the use of our Service.<br />
                <strong>*For Business Transfers* : </strong>In connection with business mergers or asset sales.<br />
                <strong>*With Affiliates* : </strong>To share data with related entities under our control.<br />
                <strong>*With Business Partners* : </strong>To offer products or services.<br />
                <strong>*With Other Users* : </strong>When you interact publicly within the Service.<br />
                <strong>*With Your Consent* : </strong> For any other purpose with your explicit consent.<br /><br />
                <strong>*Retention of Personal Data*</strong>
                <br /><br />
                We retain personal data only as long as necessary for our purposes and legal obligations. Usage Data is generally kept for shorter periods unless needed for security or legal reasons.
                <br /><br />
                <strong>*Transfer of Personal Data*</strong>
                <br /><br />
                Your data may be transferred to and processed in locations outside of your jurisdiction. By using our Service, you consent to such transfers.
                <br /><br />
                <strong>*Deleting Your Personal Data*</strong>
                <br /><br />
                You have the right to request deletion of your personal data. You can manage your data through your Account settings or by contacting us.
                <br /><br />
                <strong>*Disclosure for Legal Requirements*</strong>
                <br /><br />
                We may disclose your personal data if required by law or to protect our rights, safety, or the rights of others.
                <br /><br />
                <strong>*Security of Personal Data*</strong>
                <br /><br />
                While we strive to protect your data using commercially acceptable means, no method of transmission or storage is 100% secure. We cannot guarantee absolute security.
                <br /><br />
                <strong>*Childrens Privacy*</strong>
                <br /><br />
                Our Service is not intended for individuals under 13 years of age. If we learn we have collected data from a child under 13 without parental consent, we will take steps to remove that data.
                <br /><br />
                <strong>*Links to Other Websites*</strong>
                <br /><br />
                Our Service may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. Please review their privacy policies.
                <br /><br />
                <strong>*Changes to This Privacy Policy*</strong>
                <br /><br />
                We may update our Privacy Policy from time to time. Changes will be posted on this page, with an updated "Last updated" date. You are encouraged to review this policy periodically.
                <br /><br />
                <strong>*Contact Us*</strong>
                <br /><br />
                For any questions regarding this Privacy Policy, please contact us at: support@adzeagle.com
                <br /><br />
                Feel free to adjust any specific details such as addresses or jurisdictions to fit your actual business details.
            </p>
        </div>
    );
};
const styles = {
    container: {
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto',
        backgroundColor: '#000', // Optional: Set background color to black for contrast
    },
    header: {
        color: '#fff',
        fontFamily: 'Arial, sans-serif', // Replace with your preferred font family
        fontSize: '36px',
        marginBottom: '20px',
    },
    paragraph: {
        color: '#fff',
        fontFamily: 'Arial, sans-serif', // Replace with your preferred font family
        fontSize: '18px',
        lineHeight: '1.6',
    },
};

export default PrivacyPolicyWeb;
