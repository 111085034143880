import React from 'react'
import LatestGames from '../components/homePage/LatestGames/LatestGames';
import Header from '../components/homePage/Header/Header';
import MoreAboutUs from '../components/homePage/MoreAboutUs/MoreAboutUs';
import { Box } from '@mui/material';

const Home = () => {
  return (
    <Box
      sx={{ overflow: 'hidden'}}
    >
      <Header />
      <LatestGames />
      <MoreAboutUs />
    </Box>
  )
}

export default Home