import { styled } from '@mui/material';
import Close from '@mui/icons-material/Close';

export const TitleDiv =  styled('div') (({ theme }) => ({
  marginTop: '16px',
  textAlign: 'center',
}));

export const SubtitleDiv =  styled('div') (({ theme }) => ({
  marginTop: '16px',
  textAlign: 'center',
}));

export const ModalButtonContainer =  styled('div') (({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  marginTop: '32px',
  flex: 1,
}));

export const ButtonContainer =  styled('div') (({ theme }) => ({
  marginRight: '16px',
}));

export const ButtonSeparator =  styled('div') ({
  width: '16px',
  height: '8px',
});

export const ConfirmModalStyle = {
  paddingBottom: '32px',
  background: 'black',
};

export const CloseButton =  styled(Close) ({
  left: '17px',
  top: '17px',
  cursor: 'pointer',
});
