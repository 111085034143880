import React, { useRef } from 'react'
import { Typography, Grid, Button } from "@mui/material";
import emailjs from '@emailjs/browser';
import { BoxText } from '../Header/Styled';
import { StyledTextField } from './Styled';
import { Row2 } from '../../common/CommonStyled';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { textConstants } from '../../../constants/textConstants';

const MoreAboutUs = () => {
	const form = useRef();
	const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const sendEmail = (e) => {
	e.preventDefault();

	emailjs
		.sendForm('service_aom5o06', 'template_tkwsgr7', form.current, {
			publicKey: 'iFD_G6-lAPAfc34cf',
		})
		.then(
			() => {
				console.log('SUCCESS!');
				form.current.reset();
				setOpenSnackbar(true);
			},
			(error) => {
				console.log('FAILED...', error.text);
			},
		);
};
	
  return (
		<Grid
			container
			sx={(theme) => {
				return {
					py: theme.spacing(8),
					background: 'black',
				}
			}}
		>
			<div id="contactElement" />
			<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
				<Alert
					onClose={handleClose}
					severity="success"
					variant="filled"
					sx={{ width: '100%' }}
				>
					Message sent! Thanks for contacting us.
				</Alert>
			</Snackbar>
			<Grid 
				item
				xs={12}
			>
				<BoxText>
					<Typography
						variant='h4'
						component= 'h1'
						sx={{
								fontWeight: 700,
								color: '#fff',
						}}
					>
						{textConstants.contactTitle}
					</Typography>			
				</BoxText>
				
				<BoxText>
					<Typography
						variant='p'
						component='p'
						sx={{
								py: 3,
								lineHeight: 1.6,
								color: '#fff',
						}}
					>
						{textConstants.contactDescription}
					</Typography>
				</BoxText>
			</Grid>
			<Grid
				item
				xs={12}
				sx={(theme) => {
					return {
						display: 'flex',
						justifyContent: 'center',
					}
				}}
			>
				<form ref={form} onSubmit={sendEmail}>
					<Grid 
						container 
						spacing={2} 
						columns={12} 
						sx={{
							borderRadius: '8px',
							marginY: "8px",
							paddingY: "24px",
							paddingX: '24px'
						}}
						
					>
						<Grid
							item
							xs={6}
						>
							<Row2>
								<Typography
									variant='h6'
									color="white"
								>
									{textConstants.contactName}
								</Typography>
								<Typography
									variant='h6'
									color="red"
								>
									&nbsp;*
								</Typography>
							</Row2>
							<StyledTextField
								type="text"
								variant='outlined'
								label=""
								name="name"
								fullWidth
								required
							/>
						</Grid>
						<Grid
							item
							xs={6}
						>
							<Row2>
								<Typography
									variant='h6'
									color="white"
								>
									{textConstants.contactEmail}
								</Typography>
								<Typography
									variant='h6'
									color="red"
								>
									&nbsp;*
								</Typography>
							</Row2>
							<StyledTextField
								type="text"
								variant='outlined'
								label=""
								name="email"
								fullWidth
								required
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<Row2>
								<Typography
									variant='h6'
									color="white"
								>
									{textConstants.contactMessage}
								</Typography>
								<Typography
									variant='h6'
									color="red"
								>
									&nbsp;*
								</Typography>
							</Row2>
							<StyledTextField
								type="text"
								variant='outlined'
								label=""
								name="message"
								fullWidth
								required
								rows={5}
								multiline
								inputProps={{ style: { color: "white" } }}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sx={(theme) => {
								return {
									display: 'flex',
									justifyContent: 'center',
								}
							}}
						>
							<Button type='submit' variant="contained">
								{textConstants.contactButton}
							</Button>
						</Grid>
					</Grid>
				</form>		
			</Grid>
		</Grid>
	)
}

export default MoreAboutUs