import React from 'react';
import { Box, Grid } from '@mui/material';
import Title from '../../common/Title';
import { latestGames } from '../../../constants/lastestApps';
import './customStyles.css';
import { renderItem } from './Helpers';
import { textConstants } from '../../../constants/textConstants';

const AllGames = () => {
    return (
        <Box
            sx={{
                background: 'white',
                borderRadius: '12px',
                paddingTop: '24px'
            }}
            whileHover={{ scale: 1.1 }}
        >
            <div id="gamesElement" />
            <Title text={textConstants?.ourLastestApps} textAlign="center" />
            <div
                initial={{
                    opacity: 0,
                    x: 50
                }}
                whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                        duration: 1
                    }
                }
                } viewport={{ once: false }}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                }}
            >
                <Grid
                    container
                    gap={4}
                    sx={{ justifyContent: 'center', my: '32px' }}
                >
                    {latestGames.map(renderItem)}
                </Grid>
            </div>
        </Box>
    )
}

export default AllGames;
