import React from'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/navigation/Navbar/Navbar';
import Footer from './components/navigation/Footer/Footer';
import { theme } from './theme/theme';
import { ThemeProvider } from '@mui/material/styles';
import DeviceDetectionController from './deviceDetection/DeviceDetectionController';
import { ConfirmModalProvider } from './deviceDetection/ConfirmModalContext';
import PrivacyPolicyWeb from './components/navigation/Footer/PrivacyPolicyWeb';
import AllGames from './components/homePage/LatestGames/AllGames';
import LatestGames from './components/homePage/LatestGames/LatestGames';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ConfirmModalProvider>
          <DeviceDetectionController />
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<LatestGames />} />
            <Route path="/PrivacyPolicyWeb" exact element={<PrivacyPolicyWeb/>} />  
            <Route path="/AllGames" exact element={<AllGames />} />
          </Routes>
        </ConfirmModalProvider>
      </BrowserRouter>
      <Footer />
    </ThemeProvider>
  );
}

export default App;