import React from 'react'
import { Box, Typography, Button } from "@mui/material";
import { CustomBox, BoxText } from './Styled';
import AppExample from '../../../assets/gallery1.png';
import { themeValues } from '../../../theme/theme';
import { motion } from "framer-motion";
import { textConstants } from '../../../constants/textConstants';

const text = textConstants?.homeHeaderTitle?.split(" ");

const Header = () => {
	const goToGames = () => {
		const gamesElement = document.getElementById("gamesElement");
		gamesElement.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		});
	};

  return  (
		<CustomBox component='header'>
			<BoxText 
				component='section'
				sx={(theme) => {
					return {
						[theme.breakpoints.down('md')]: {
							width: '80%',
							textAlign: 'center',
						}
					}
				}}
			>
				<BoxText
					component='section'
					sx={(theme) => {
						return {
							paddingLeft: 0,
						}
					}}
				>
					<Typography
						variant='h2'
						component= 'h1'
						sx={(theme) => {
							return {
								fontWeight: 700,
								color: themeValues.brandColors.yellow,
								[theme.breakpoints.up('sm')] : {
									display: 'flex',
									textWrap: 'wrap'
								}
							}
						}}
					>
						{text.map((el, i) => (	
							<motion.span
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{
									duration: 2,
									delay: i / 6,
								}}
								key={i}
								style={{
									display: 'flex',
									flexDirection: 'row',
									textAlign: 'center',
								}}
							>
								{el}&nbsp;				
							</motion.span>
						))}
					</Typography>
				</BoxText>

				<Typography
					variant='p'
					component='p'
					sx={{
							py: 3,
							lineHeight: 1.6,
							color: '#fff',
					}}
				>
						{textConstants?.homeHeaderDescription}
				</Typography>

				<Button type='submit' variant="contained" onClick={goToGames}>
					{textConstants?.homeHeaderButton}
				</Button>
			</BoxText>

			<Box 
				sx={theme => ({
					[theme.breakpoints.down('md')]:{
							flex: '1',
							paddingTop: '30px',
							alignSelf: 'center',
					},
					[theme.breakpoints.up('md')]:{
							flex: '2',
							alignSelf: 'flex-end',
					},
				})}
			>
				<img 
					src={AppExample}
					alt='Example of our apps'
					width="100%"
				/>
			</Box>
		</CustomBox>
	)
}

export default Header