export const availableApps = {
  wolfearn: 'com.wolfearn.easyearning',
  cashsamurai: 'com.pocketfull.rewardpocket',
  cashmamba: 'com.cashmamba.easyearning',
  mathschamp: 'com.mathschamp.android',
  cashzebra: 'com.cashzebra.android',
  cashbear: 'com.cashbear.app',
  cashwarrior: 'com.cashwarrior.easyearning',
  cashgorilla: 'com.cashgorilla.app',
  rewardsprint: 'com.rewardsprint.android',
  earnbucks: 'com.bucksearn.earnbucks',
}