import React from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import { Box, Grid, Button, Icon } from '@mui/material'; // Import Icon
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // Import the arrow icon
import Title from '../../common/Title';
import { latestGames } from '../../../constants/lastestApps';
import './customStyles.css';
import { renderItem } from './Helpers';
import { motion } from "framer-motion";
import { textConstants } from '../../../constants/textConstants';

const LatestGames = () => {
    const navigate = useNavigate();

    const handleViewMore = () => {
        navigate('/AllGames'); // This should match the route for your all games screen
    };

    return (
        <Box
            sx={{
                background: 'white',
                borderRadius: '12px',
                paddingTop: '24px'
            }}
            whileHover={{ scale: 1.1 }}
            component={motion.div}
        >
            <div id="gamesElement" />
            <Title text={textConstants?.ourLastestApps} textAlign="center" />
            <motion.div
                initial={{
                    opacity: 0,
                    x: 50
                }}
                whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                        duration: 1
                    }
                }}
                viewport={{ once: false }}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                }}
            >
                <Grid
                    container
                    gap={4}
                    sx={{ justifyContent: 'center', my: '32px' }}
                >
                    {/* Show only the first 3 games */}
                    {latestGames.slice(0, 3).map(renderItem)}

                    <Button
                        onClick={handleViewMore}
                        variant="contained"
                        sx={{
                            background: 'linear-gradient(to right, #ff7e5f, #feb47b)', // Warm gradient background
                            borderRadius: '24px', // More rounded corners
                            padding: '12px 24px', 
                            color: 'white', 
                            textTransform: 'none', 
                            fontStyle: 'italic', 
                            fontFamily: 'Arial, sans-serif',
                            fontWeight: 'bold',
                            width: 'auto', // Auto width for better fitting text
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // More pronounced shadow
                            transition: 'all 0.3s ease', // Smooth transition
                            '&:hover': {
                                background: 'linear-gradient(to right, #feb47b, #ff7e5f)', // Reverse warm gradient on hover
                                boxShadow: '0 12px 24px rgba(0, 0, 0, 0.3)', // Stronger shadow on hover
                                transform: 'translateY(-3px)', // Slight lift on hover
                            },
                        }}
                        endIcon={<ArrowForwardIosIcon />} // Add the arrow icon at the end
                    >
                        View More
                    </Button>

                </Grid>
            </motion.div>
        </Box>
    );
};

export default LatestGames;