import { 
  Box, 
  Stack, 
  styled, 
} from '@mui/material'

export const StackColumn = styled(Stack) (() => ({
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flex: 1,
  gap: 8,
  textAlign: 'center',
}));

export const BoxRow = styled(Box) (({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '#ededed',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 30,
  }
}));