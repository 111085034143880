import {
  Toolbar,
  List,
  styled,
} from '@mui/material';

export const StyledToolbar = styled(Toolbar) ({
  display: 'flex',
  justifyContent: 'space-between', 
});

export const ListMenu = styled(List)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up("sm")] : {
      display: "flex",
  },
}));

export const drawerWidth = 240;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 5),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));