import { useEffect, useState } from 'react';
import { appRedirectionSchemas } from '../constants/appRedirection';
import { availableApps } from '../constants/availableApps';

const DeviceDetectionController = () => {
  const [savedRedirectionData, setSavedRedirectionData] = useState('');

  const onRedirectToAppStore = () => {
    console.log('Redirecting to app store...', savedRedirectionData);
    if (!savedRedirectionData) return;

    // Find the app key from the available apps list based on URL
    const currentApp = Object.keys(availableApps).find(item => savedRedirectionData.includes(item));
    const referralCode = savedRedirectionData?.split(`${currentApp}/`)?.[1];

    if (currentApp && referralCode && appRedirectionSchemas[currentApp]) {
      const redirectionLink = `${appRedirectionSchemas[currentApp]}${referralCode}`;

      // Redirect to the Play Store/App Store on any device
      window.open(redirectionLink, '_self');
    } else {
      console.log('Invalid schema or missing data', currentApp, referralCode);
    }
  };

  const fullCurrentPath = `${window.location.href}`;

  useEffect(() => {
    // Save the current URL path on initial load
    if (savedRedirectionData === '' && fullCurrentPath) {
      setSavedRedirectionData(fullCurrentPath);
    }
  }, [savedRedirectionData, fullCurrentPath]);

  useEffect(() => {
    // Check if the URL includes an app key and then trigger redirection
    if (savedRedirectionData !== '' && Object.keys(availableApps).some(item => savedRedirectionData.includes(item))) {
      onRedirectToAppStore();
    }
  }, [savedRedirectionData]);

  return null;
};

export default DeviceDetectionController;