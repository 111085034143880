import React from 'react'
import {
	AppBar,
	Typography,
	Box,
	ListItemText,
	ListItemButton,
	ListItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { StyledToolbar } from './Styled';
import { themeValues } from '../../../theme/theme';
import GooglePlay from '../../../assets/getitgoogleplay.webp'
import { googlePlayDeveloperLink } from '../../../constants/socialMedia';
import { Row } from '../../common/CommonStyled';
import { textConstants } from '../../../constants/textConstants';

const Navbar = () => {
	const navigate = useNavigate();

	const navigateToContact = () => {
		const contactElement = document.getElementById("contactElement");
		contactElement.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		});
	}
	return (
		<AppBar 
			component="nav" 
			position="sticky"
			sx={{
				width: '100vw',
				backgroundColor: themeValues.brandColors.black,
			}}
			elevation={0}
		>
			<StyledToolbar sx={{ height: { xs: '92px', sm: 'auto' }}}>
				<Typography
					variant="h6"
					component="h3"
					color="white"
					onClick={() => navigate('/')}
					sx={{ cursor: 'pointer' }}
				>
					{textConstants.navbarTitle}
				</Typography>

				<Row 
					gap="48px" 
					sx={{
						display: { xs: 'none', sm: 'flex' }
					}}
				>
					<ListItem sx={{ width: 'auto' }}>
						<ListItemButton
							onClick={navigateToContact}
							sx={{
									color: '#fff',
									"&:hover": {
											backgroundColor: 'transparent',
											color: '#1e2a5a',
									}
							}}
						>
							<ListItemText primary="Contact us" />
						</ListItemButton>
					</ListItem>

					<Box
						onClick={() => window.open(googlePlayDeveloperLink, '_blank')}
						sx={{
							height: '100px',
							display: 'flex',
							alignItems: 'center',
							cursor: 'pointer',
						}}
					>
						<img 
							src={GooglePlay}
							alt='Get it on Google Play'
							width="200px"
						/>
					</Box>
				</Row>
			</StyledToolbar>
		</AppBar>
	)
}

export default Navbar;
