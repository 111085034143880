export const renderItem = (item) => {
  return (
    <div
      key={item.name}
      className="card"
      style={{ backgroundImage: `url(${item?.image})`, cursor: 'pointer' }}
      onClick={() => window.open(item?.link, '_blank')}
    >
      <div className="card-content">
        <h2 className="card-title">{item.name}</h2>
        <p className="card-body">
          {item?.description}
        </p>
      </div>
    </div>
  )
};